<template>
  <v-app>
    <v-app-bar app class="app-bar" color="purple darken-4" dark>
      <div
        class="d-flex align-center justify-space-between"
        style="width: 100%"
      >
        <router-link class="text-decoration-none white--text" to="/">
          <h1>Zudo</h1>
        </router-link>
        <v-btn
          v-if="$route.path !== '/clear'"
          class="white--text mx-4"
          @click="$router.push('/clear')"
          color="red lighten-1"
        >
          <v-icon> {{ icons.mdiBroom }} </v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mdiBroom } from "@mdi/js";

export default {
  name: "App",

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      icons: {
        mdiBroom,
      },
    };
  },
};
</script>

<style scoped>
.main {
  background-color: #19113a;
}
</style>
